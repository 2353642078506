import React from 'react';
import { appName } from '../../config/config';
import { NavLink } from 'react-router-dom';

const Carousel = () => {
    return ( <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
                <img className="w-100" src="/assets/img/carousel-1.jpg" alt="Image" />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{maxWidth: 900}}>
                        <h1 className="display-1 text-white mb-md-4 animated zoomIn">Immigration Experts</h1>
                        <h5 className="text-white mb-3 animated slideInDown">Whether you’re seeking to study, work, or settle abroad, {appName} is here to make your immigration process smooth and stress-free. Let us help you open the door to new opportunities and a brighter future.</h5>
                        <a href="quote.html" className="d-none btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a>
                        <NavLink to="/contact" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</NavLink>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/carousel-2.jpg" alt="Image" />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{maxWidth: 900}}>
                        <h1 className="display-1 text-white mb-md-4 animated zoomIn">Immigration Experts</h1>
                        <h5 className="text-white mb-3 animated slideInDown">At {appName}, we believe in a world where borders do not limit opportunities. Our vision is to be the leading immigration consultancy, known for our exceptional service and unwavering commitment to our clients.</h5>
                        <a href="quote.html" className="d-none btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a>
                        <NavLink to="/contact" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</NavLink>
                    </div>
                </div>
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
            data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
            data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div> );
}
 
export default Carousel;