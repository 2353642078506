import React, { useEffect, useState } from 'react';
import BlogArticles from '../blog-articles/BlogArticles';
import { AdminService } from '../../services/adminService';
import URLs from '../../config/urls';
import { HelperService } from '../../services/helperService';

const Success = () => {
    let [achievements, setAchievements] = useState([]);

    const getAchievements = ()=>{
        AdminService.getAchievements(false).then(res=>{
            if(res && res.data && res.data.data) {
                res.data.data.map(x=>{
                    x.dateUploaded = HelperService.formatDisplayDate(x.dateUploaded);
                })
                setAchievements(res.data.data.filter(x=>x.isEnabled));
            }
        }).catch(()=>{});
    }

    useEffect(()=>{
        getAchievements();
    }, [])

    return (<>
        {achievements.length ? <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                <h5 className="fw-bold text-primary text-uppercase">Our Success</h5>
                <h1 className="mb-0">We believe in turning challenges into opportunities.</h1>
                <p>Our relentless pursuit of innovation and excellence has led us to achieve remarkable milestones. Here are some highlights of our journey</p>
            </div>
            <div className="row g-5">
                {achievements.map(a=>{
                    return <div key={a.Id} class="col-lg-4 wow slideInUp" data-wow-delay="0.3s"><BlogArticles imageUrl={URLs.viewFile+a.uploadedFile} name={a.name} date={a.dateUploaded} title={a.title} description={a.description} /></div>
                })}
            </div>
        </div>  : null}
    </>);
}

export default Success;