const URLs = {
    login: "/api/Login/LoginUser",
    getFeedbacks: "/api/Feedback/getFeedbacks",
    updateFeedback: "/api/Feedback/UpdateFeedback",
    updateAchievement: "/api/Admin/updateAchievement",
    getAchievements: "/api/Admin/GetAchievements",
    deleteAchievement: "/api/Admin/DeleteAchievement",
    deleteFeedback: "/api/Feedback/DeleteFeedback",
    upload: "/api/attachment/UploadAttachment",
    viewFile: "/api/attachment/View/",
    logoutUser: "/api/Login/LogoutUser",
    userDetails: "/api/Login/UserDetails",
    sendMail: "/api/Email",
    resetPassword: "/api/Login/ResetPassword",
    resetPasswordConfirm: "/api/Login/ResetPasswordConfirm"
}

export default URLs;