import React from 'react';
import Accordion from '../../accordion/Accordion';

const CareGiver = () => {
    const accordionData = {
        title: 'Frequently asked Questions',
        data: [
            { title: 'Is it possible for a caregiver to obtain permanent residence in Canada?', description: 'If you apply for permanent residence under the Live-in Caregiver Class, you may be eligible to apply for an open work permit as a live-in caregiver. Additionally, you have to have worked as a live-in caregiver for at least 2,900 hours within a maximum of 4 years, or 3,900 hours total, and held valid work permits under the program for at least two years.' },
            { title: 'What is the caregiver IELTS passing score?', description: 'The Caregiver program’s passing score is CLB Level 4.' },
            { title: 'What is the Canadian caregiver age limit?', description: 'While there isn’t typically an age restriction for caregivers, it should be noted that if you are under 16 you cannot work in Canada without your parent’s permission.' }
        ]
    };
    return (<>
        <div className="mb-5">
            <img class="img-fluid w-100 rounded mb-5" src="/assets/img/care-giver.jpg" alt="" />
            <p>Through the caregiver program, temporary residents holding a caregiver work permit and those who received approval for one prior to November 30, 2014, based on a favorable Labour Market Impact Assessment (LMIA) application from their employer, can become permanent residents of Canada.</p>
            <h3 className='mb-3'>Caregiver Program Requirement</h3>
            <p>The requirements for the Caregive program in Canada are as follows:</p>
            <div className="row g-5 mb-3">
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/care-giver/1.png' />
                        <h6 className="mb-3-invalid">Positive LMIA</h6>
                        <p className="m-0">Need to be able to accept a job offer from a Canadian company with a favorable LMIA</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/care-giver/2.png' />
                        <h6 className="mb-3-invalid">prior experience as a caregiver</h6>
                        <p className="m-0">A minimum of one year of full-time caregiver employment or six months of training</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/care-giver/3.png' />
                        <h6 className="mb-3-invalid">Language Ability</h6>
                        <p className="m-0">Test of language skills in either French or English, Canadian diploma or degree, or comparable overseas study</p>
                    </div>
                </div>
            </div>
            <Accordion data={accordionData.data} title={accordionData.title} uniqueId='careVisaAcc' />
        </div>
    </>);
}

export default CareGiver;