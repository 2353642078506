import React, { useEffect, useState } from 'react';
import Carousel from '../carousel/Carousel';
import './Header.css';
import { altPhone, altPhoneDisplay, appName, email, headerBg, location, pageTitle, phone, phoneDisplay, socialNetworkingLinks } from '../../config/config';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Networker } from '../../services/networker';

const Header = () => {
    const loc = useLocation();
    let [home, setHome] = useState(true);
    let [pathname, setPath] = useState('');
    // useEffect(()=>{
    //     setTimeout(()=>{
    //         Networker.toggleSpinner(false)
    //     }, 3000);
    // });
    useEffect(() => {
        if (loc.pathname == '/') {
            setHome(true);
        }
        else {
            setHome(false);
            setPath(loc.pathname);
        }
    }, [loc]);
    return (<>
        <div className="container-fluid bg-dark px-5 py-2 d-none d-lg-block">
            <div className="row gx-0">
                <div className="col-lg-10 text-center text-lg-start mb-2 mb-lg-0">
                    <div className="d-inline-flex align-items-center" style={{ height: 45 }}>
                        <a target='_blank' href={"https://www.google.com/maps/search/" + location} className='d-none'><small className="me-3 text-light"><i className="fa fa-map-marker-alt me-2"></i>{location}</small></a>
                        <a href={'tel:' + phone}><small className="me-3 text-light"><i className="fa fa-phone me-2"></i>{phoneDisplay}</small></a>
                        <a href={'tel:' + altPhone}><small className="me-3 text-light"><i className="fa fa-phone me-2"></i>{altPhoneDisplay}</small></a>
                        <a href={'mailto:' + email}><small className="me-3 text-light"><i className="fa fa-envelope-open me-2"></i>{email}</small></a>
                        <small className="text-light"><i className="fa fa-clock me-2"></i> Mon - Fri : 10:00 to 17:00</small>
                    </div>
                </div>
                <div className="col-lg-2 text-center text-lg-end">
                    <div className="d-inline-flex align-items-center" style={{ height: 45 }}>
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target='_blank' href={socialNetworkingLinks.x}><i className="fab fa-x-twitter fw-normal"></i></a>
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target='_blank' href={socialNetworkingLinks.facebook}><i className="fab fa-facebook-f fw-normal"></i></a>
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2 d-none" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target='_blank' href={socialNetworkingLinks.instagram}><i className="fab fa-instagram fw-normal"></i></a>
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle d-none" href=""><i className="fab fa-youtube fw-normal"></i></a>
                        <Link className="d-none btn btn-sm btn-outline-light btn-sm-square rounded-circle" to="/login"><i className="fa-solid fa-lock"></i></Link>
                    </div>
                </div>
            </div>
        </div>
        <hr className='m-0' />
        <div className="container-fluid position-relative p-0">
            <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
                <Link to="/" className="navbar-brand p-0">
                    <h1 className="m-0"><img src="/assets/img/Logo-Icon.png" alt="Logo" /> <img className='app-name' src="/assets/img/App-Name.png" alt="Logo" /></h1>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">
                        <NavLink to='/' className="nav-item nav-link">Home</NavLink>


                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Services</a>
                            <div className="dropdown-menu m-0 two-level">
                                <div className='row'>
                                    <div className='col-12 col-lg-6'>
                                        <h5 className='pt-3 px-3'>Immigration</h5>
                                        <Link to="/immigration/family" className="dropdown-item">Family Sponsorship</Link>
                                        <Link to="/immigration/spouse" className="dropdown-item">Spouse Sponsorship</Link>
                                        <Link to="/immigration/expressentry" className="dropdown-item">Express Entry</Link>
                                        <Link to="/immigration/pnp" className="dropdown-item">Provincial Nomination</Link>
                                        <Link to="/immigration/hnc" className="dropdown-item">Humanitarian And Compassionate Ground</Link>
                                    </div>
                                    <div className='col-12 col-lg-6'>
                                        <h5 className='pt-3 px-3'>Visa/Permit</h5>
                                        <Link to="/visa/business" className="dropdown-item">Business Visa</Link>
                                        <Link to="/visa/visitor" className="dropdown-item">Visitor Visa</Link>
                                        <Link to="/visa/student" className="dropdown-item">Students Visa</Link>
                                        <Link to="/visa/work" className="dropdown-item">Work Permit</Link>
                                        <Link to="/visa/super" className="dropdown-item">Super Visa</Link>
                                        <Link to="/visa/caregiver" className="dropdown-item">Caregiver Work Permit</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <NavLink to='/calculators/crs' className="nav-item nav-link">CRS</NavLink>
                        <div className="nav-item dropdown d-none">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Immigration</a>
                            <div className="dropdown-menu m-0">
                                <Link to="/immigration/family" className="dropdown-item">Family Sponsorship</Link>
                                <Link to="/immigration/spouse" className="dropdown-item">Spouse Sponsorship</Link>
                                <Link to="/immigration/expressentry" className="dropdown-item">Express Entry</Link>
                                <Link to="/immigration/pnp" className="dropdown-item">Provincial Nomination</Link>
                                <Link to="/immigration/hnc" className="dropdown-item">Humanitarian And Compassionate Ground</Link>
                            </div>
                        </div>
                        <div className="nav-item dropdown d-none">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Visa/Permit</a>
                            <div className="dropdown-menu m-0">
                                <Link to="/visa/business" className="dropdown-item">Business Visa</Link>
                                <Link to="/visa/visitor" className="dropdown-item">Visitor Visa</Link>
                                <Link to="/visa/student" className="dropdown-item">Students Visa</Link>
                                <Link to="/visa/work" className="dropdown-item">Work Permit</Link>
                                <Link to="/visa/super" className="dropdown-item">Super Visa</Link>
                                <Link to="/visa/caregiver" className="dropdown-item">Caregiver Work Permit</Link>
                            </div>
                        </div>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Resources</a>
                            <div className="dropdown-menu m-0">
                                <Link to="/faqs" className="dropdown-item">Immigration FAQ’s</Link>
                            </div>
                        </div>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Points Calculator</a>
                            <div className="dropdown-menu m-0">
                                <Link to="/calculators/bc-pnp" className="dropdown-item">2022 BC PNP Point Calculator</Link>
                                <Link to="/calculators/bc-pnp-business" className="dropdown-item">BC PNP Business Investor Calculator</Link>
                                <Link to="/calculators/skilled-worker" className="dropdown-item">Canada 67 Point Calculator / Skilled Worker Calculator</Link>
                                <Link to="/calculators/sinp" className="dropdown-item">Saskatchewan PNP (SINP) Point Calculator</Link>
                                <Link to="/calculators/nova-scotia" className="dropdown-item">Nova Scotia 67 Point Calculator (NSNP)</Link>
                            </div>
                        </div>
                        <NavLink to='/news' className="nav-item nav-link">News</NavLink>
                    </div>
                    <butaton type="button" className="btn text-primary ms-3 d-none" data-bs-toggle="modal" data-bs-target="#searchModal"><i className="fa fa-search"></i></butaton>
                    <a href="https://htmlcodex.com/startup-company-website-template" className="btn btn-primary py-2 px-4 ms-3 d-none">Download Pro Version</a>
                </div>
            </nav>
            {home ? <Carousel></Carousel> : <div class={"container-fluid bg-primary py-5 bg-header " + headerBg[pathname]} style={{ marginBottom: 90 }}>
                <div class="row p-5">
                    <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                        <h1 class="display-4 text-white animated zoomIn">{pageTitle[pathname] || 'Admin'}</h1>
                        {/* <Link to="/" class="h5 text-white">Home</Link>
                        <i class="far fa-circle text-white px-2"></i>
                        <a href="" class="h5 text-white">{pageTitle[pathname] || ''}</a> */}
                    </div>
                </div>
            </div>}

        </div>
    </>);
}

export default Header;