import React from 'react';
import Accordion from '../../accordion/Accordion';

const SpouseSponsorship = () => {
    const accordionData = {
        title: 'Frequently asked Questions',
        data: [
            {title:'How much time does it take in Canada to sponsor a spouse?', description:'Processing a sponsorship application from beginning to end takes about a year. Generally speaking, they don’t get handled any quicker than a year, however depending on your instance, it can take longer.'},
            {title:'Is income required for me to sponsor my spouse?', description:'Most of the time, you can sponsor your partner, spouse, or dependent child without having to meet any financial requirements. If you are sponsoring a dependent kid who has one or more dependent children of their own, then you merely need to demonstrate that you have sufficient income to meet the requirements.'},
            {title:'Can one refuse to sponsor their spouse?', description:'The IRCC carefully examines each connection to make sure the petitioner is not using marriage fraud as a cover for their immigration. The application will be denied and the sponsored spouse may be prohibited from entering Canada for five years for misrepresenting their marital status if the officer is not persuaded that the marriage is real.'}
        ]
    };
    return (<>
        <div class="mb-5">
            <img class="img-fluid w-100 rounded mb-5" src="/assets/img/spouse-sponsorship.jpg" alt="" />
            <p>Spousal sponsorship is a component of immigration under the family class. A spouse or common-law partner may be sponsored for permanent residence in Canada under this scheme by a Canadian citizen or permanent resident. Members of the family class may be sponsored by citizens or permanent residents of Canada, but the government mandates that newly arrived immigrants have care and support from their sponsors.</p>
            <p>Through this immigration policy, both the sponsor and the sponsored must be able to document their relationship and fall into one of three categories in order to be eligible for a visa;</p>
            <p><b>Spouse</b>:  The sponsor and the sponsored person must be legally married, and the marriage must be recognized both in Canada and in the country where it occurred.</p>
            <p><b>Common-Law Partner</b>: The couple must have lived together in a genuine, committed relationship for at least 12 consecutive months.</p>
            <p><b>Conjugal Partner</b>: This applies to individuals in a committed relationship with the sponsor but who have been unable to live together or marry due to significant legal or immigration barriers. They must show that they have been in the relationship for at least one year.</p>
            <p>Both parties must provide evidence to prove the legitimacy of their relationship and meet the specific criteria established by Immigration, Refugees and Citizenship Canada (IRCC).</p>
            <h3 class="mb-3">Common Law Partner's Spouse Requirement</h3>
            <ul className='immigration-list'>
                <li><i className='bi bi-arrow-right-circle'></i> Must be at least eighteen years old</li>
                <li><i className='bi bi-arrow-right-circle'></i> Citizenship or permanent residency in Canada is required.</li>
                <li><i className='bi bi-arrow-right-circle'></i> Not having had a spouse sponsor for the previous five years</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of financial capability</li>
                <li><i className='bi bi-arrow-right-circle'></i> Additionally, they should not be in bankruptcy or have a spotless criminal record.</li>
            </ul>
            <Accordion data={accordionData.data} title={accordionData.title} uniqueId='familyAcc'/>
        </div>
    </>);
}

export default SpouseSponsorship;