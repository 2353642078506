import React from 'react';

const FeaturesSection = () => {
    return ( <>
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth:600}}>
                <h5 className="fw-bold text-primary text-uppercase">Why Choose Us</h5>
                <h1 className="mb-0">Here’s why we stand out</h1>
                <p>Choosing the right immigration partner can make all the difference in your journey.</p>
            </div>
            <div className="row g-5">
                <div className="col-lg-4">
                    <div className="row g-5">
                        <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: 60, height: 60}}>
                                <i className="fa fa-cubes text-white"></i>
                            </div>
                            <h4>Expert Guidance</h4>
                            <p className="mb-0 text-justify">Our team of seasoned immigration professionals brings years of experience and in-depth knowledge to navigate the complexities of immigration laws and procedures.</p>
                        </div>
                        <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: 60, height: 60}}>
                                <i className="fa fa-award text-white"></i>
                            </div>
                            <h4>Personalized Service</h4>
                            <p className="mb-0 text-justify">We understand that every client’s situation is unique. Our tailored approach ensures that your specific needs and goals are met with precision and care.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4  wow zoomIn" data-wow-delay="0.9s" style={{minHeight: 350}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s" src="/assets/img/feature.jpg" style={{objectFit:'cover'}} />
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="row g-5">
                        <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: 60, height: 60}}>
                                <i className="fa fa-users-cog text-white"></i>
                            </div>
                            <h4>Proven Success</h4>
                            <p className="mb-0 text-justify">With a high success rate in visa approvals and satisfied clients worldwide, our track record speaks for itself. We are committed to turning your immigration dreams into reality.</p>
                        </div>
                        <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: 60, height: 60}}>
                                <i className="fa fa-user text-white"></i>
                            </div>
                            <h4>Client-Centric Approach</h4>
                            <p className="mb-0 text-justify">Your satisfaction is our top priority. We go above and beyond to ensure you feel supported, informed, and confident in your immigration journey.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </> );
}
 
export default FeaturesSection;