export const appName = 'Play Fair Immigration';
export const phone = '+16042565300';
export const phoneDisplay = '+1-604-256-5300';
export const altPhone = '+16042565400';
export const altPhoneDisplay = '+1-604-256-5400';
export const email = 'info@playfairimm.com';
export const location = '7337 137 St, Surrey, BC V3W 1A4, Canada';
export const pageTitle = {
    "/contact": "Contact Us",
    "/about": "About Us",
    "/immigration/family": "Family Sponsorship",
    "/immigration/spouse" : "Spouse Sponsorship",
    "/immigration/expressentry": "Express Entry",
    "/immigration/pnp": "Provincial Nomination",
    "/immigration/hnc": "Humanitarian And Compassionate Ground",
    "/visa/business": "Business Visa",
    "/visa/caregiver": "Care Giver Work Permit",
    "/visa/student": "Student Visa",
    "/visa/super": "Super Visa",
    "/visa/visitor": "Visitor Visa",
    "/visa/work": "Work Permit",
    "/news": "News",
    "/faqs": "Frequently Asked Questions",
    "/login": "Login",
    "/services": "Our Services",
    "/testimonial": "Testimonial",
    "/feedback": "Feedback",
    "/calculators/bc-pnp": "BC PNP Point Calculator",
    "/calculators/bc-pnp-business": "BC PNP Entrepreneur Immigration Point Calculators",
    "/calculators/skilled-worker": "Canada 67 Point Calculator / Skilled Worker Calculator",
    "/calculators/sinp": "Saskatchewan PNP (SINP) Point Calculator",
    "/calculators/nova-scotia": "Nova Scotia Immigration Points Calculator",
    "/calculators/crs":"Canada CRS Score Calculator"
}

export const headerBg = {
    "/immigration/family" : "family-sponsorship",
    "/immigration/spouse" : "spouse-sponsorship",
    "/immigration/expressentry": "express-entry",
    "/immigration/pnp": "pnp",
    "/immigration/hnc": "hnc",
    "/visa/business": "business-visa",
    "/visa/caregiver": "caregiver-visa",
    "/visa/student": "student-visa",
    "/visa/super": "super-visa",
    "/visa/visitor": "visitor-visa",
    "/visa/work": "work-permit"
}

export const socialNetworkingLinks = {
    x: "",
    facebook: "https://www.facebook.com/playfairimmigration",
    instagram: "https://www.instagram.com/playfairimmigration/"
}