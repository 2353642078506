import React from 'react';
import Accordion from '../../accordion/Accordion';

const WorkPermit = () => {
    const accordionData = {
        title: 'Frequently asked Questions',
        data: [
            { title: 'How much time does it take to process a work permit visa?', description: 'There are various categories for work permits, and the length of time it takes to process a work permit depends on its category. The processing period ranges from 30 to 120 days on average.' },
            { title: 'How much does it cost to apply for a work permit?', description: 'It differs according to the nation. It’s hard to say.' },
            { title: 'What information is crucial for someone applying for a work permit?', description: 'You must be aware of the specifics of training, expertise, and cost. Is it feasible to work while attending college and a few other things?' }
        ]
    };
    return (<>
        <div className="mb-5">
            <img class="img-fluid w-100 rounded mb-5" src="/assets/img/work-permit.jpg" alt="" />
            <h3 className='mb-3'>
                Over 150,000 qualified foreign workers are granted temporary work visas in Canada each year.
            </h3>
            <p>An amazing opportunity for entrepreneurs has emerged as the world grows more open to cross-border trade. Trade and business between nations are made easier with the help of business visas. An employment letter from a Canadian employer is required for the candidate to be able to work in Canada. To be employed in Canada, you must: Your employer requests an assessment of the labor market impact before hiring you. Your company provides an employment letter to Citizenship and Immigration Canada (CIC) and pays the compliance fee. To enter and remain in Canada as well as to apply for a work permit, you must fulfill the requirements. Your indefinite stay in Canada is not assured by a work permit. Please go to this website’s “Immigration” section to learn how to apply for permanent residence. For additional details about the choice and work permit</p>
            <h3 className='mb-3'>Checklist for Work Permits</h3>
            <ul className='immigration-list'>
                <li><i className='bi bi-arrow-right-circle'></i> A recent photo needs to be supplied.</li>
                <li><i className='bi bi-arrow-right-circle'></i> A passport that is valid</li>
                <li><i className='bi bi-arrow-right-circle'></i> Policy for travel insurance</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of accommodations</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of financial capability</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of paid visa application fees</li>
            </ul>
            <h3 className='mb-3'>
                For employment in Canada, you must meet:
            </h3>
            <p>
                In order to hire you, your employer requests a Labour Market Impact Assessment. Your company provides Citizenship and Immigration Canada (CIC) with an employment letter and pays the compliance fee. To enter, remain in, and be granted a work permit in Canada, you must fulfill the requirements.
            </p>
            <div className="row g-5 mb-3">
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/business/1.png' />
                        <h6 className="mb-3-invalid">Complete the necessary form.</h6>
                        <p className="m-0">To apply for and receive the desired foreign visa, you must fill out the necessary form.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/business/2.png' />
                        <h6 className="mb-3-invalid">Send in Your Original Records</h6>
                        <p className="m-0">To obtain the visa on time, you must submit the original document along with other information.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/business/3.png' />
                        <h6 className="mb-3-invalid">Obtain The Visa Reference Guide</h6>
                        <p className="m-0">You will receive your resource materials for a visa in a few days after finishing all the activities.</p>
                    </div>
                </div>
            </div>
            <h3 className='mb-3'>Where Can I Apply for a Business Visa and How Do I Do It?</h3>
            <p>For a business trip, you need to apply for a Business Schengen Visa at the consulate or embassy of the country you will be visiting most frequently.</p>
            <Accordion data={accordionData.data} title={accordionData.title} uniqueId='workVisaAcc' />
        </div>
    </>);
}

export default WorkPermit;