import React from 'react';
import { appName, email, location, phone, phoneDisplay, socialNetworkingLinks } from '../../config/config';
import './Footer.css';
import { Link, NavLink } from 'react-router-dom';

const Footer = () => {
    return (<>
        <div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container">
                <div className="row gx-5">
                    <div className="col-lg-4 col-md-6 footer-about">
                        <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                            <Link to="/" className="navbar-brand">
                                {/* <h1 className="m-0 text-white"><i className="fa fa-user-tie me-2"></i>{appName}</h1> */}
                                <img src="/assets/img/Logo.png" alt={appName} className='footer-logo' />
                            </Link>
                            <p className="mt-3 mb-4">Our mission is to enable a smooth and seamless application process and subsequent immigration to empower clients to achieve their goal via our world-class consultancy services.</p>
                            <form className='d-none' action="">
                                <div className="input-group">
                                    <input type="text" className="form-control border-white p-3" placeholder="Your Email" />
                                    <button className="btn btn-dark">Sign Up</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-6">
                        <div className="row gx-5">
                            <div className="col-lg-4 col-md-12 pt-5 mb-5">
                                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                    <h3 className="text-light mb-0">Get In Touch</h3>
                                </div>
                                <div className="d-flex mb-2">
                                    <i className="bi bi-geo-alt text-primary me-2"></i>
                                    <a className='text-white' target='_blank' href={"https://www.google.com/maps/search/" + location}><p className="mb-0">{location}</p></a>
                                </div>
                                <div className="d-flex mb-2">
                                    <i className="bi bi-envelope-open text-primary me-2"></i>
                                    <a className='text-white' href={'mailto:' + email}><p className="mb-0">{email}</p></a>
                                </div>
                                <div className="d-flex mb-2">
                                    <i className="bi bi-telephone text-primary me-2"></i>
                                    <a className='text-white' href={'tel:' + phone}><p className="mb-0">{phoneDisplay}</p></a>
                                </div>
                                <div className="d-flex mt-4">
                                    <a className="btn btn-primary btn-square me-2" href={socialNetworkingLinks.x} target='_blank'><i className="fab fa-x-twitter fw-normal"></i></a>
                                    <a className="btn btn-primary btn-square me-2" href={socialNetworkingLinks.facebook} target='_blank'><i className="fab fa-facebook-f fw-normal"></i></a>
                                    <a className="d-none btn btn-primary btn-square me-2" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                    <a className="btn btn-primary btn-square" href={socialNetworkingLinks.instagram} target='_blank'><i className="fab fa-instagram fw-normal"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                    <h3 className="text-light mb-0">Quick Links</h3>
                                </div>
                                <div className="link-animated d-flex flex-column justify-content-start">
                                    <NavLink to='/' className="text-light mb-2" ><i className="bi bi-arrow-right text-primary me-2"></i>Home</NavLink>
                                    <NavLink to='/about' className="text-light mb-2"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</NavLink>
                                    <NavLink to='/services' className="text-light mb-2"><i className="bi bi-arrow-right text-primary me-2"></i>Our Services</NavLink>
                                    <NavLink to='/feedback' className="text-light mb-2"><i className="bi bi-arrow-right text-primary me-2"></i>Feedback</NavLink>
                                    <NavLink to='/login' className="text-light mb-2"><i className="bi bi-arrow-right text-primary me-2"></i>Admin Panel</NavLink>
                                    <NavLink to='/contact' className="text-light"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</NavLink>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                    <h3 className="text-light mb-0">Popular Links</h3>
                                </div>
                                <div className="link-animated d-flex flex-column justify-content-start">
                                    <NavLink className="text-light mb-2" to="/immigration/expressentry"><i className="bi bi-arrow-right text-primary me-2"></i>Express Entry</NavLink>
                                    <NavLink className="text-light mb-2" to="/visa/visitor"><i className="bi bi-arrow-right text-primary me-2"></i>Visitor Visa</NavLink>
                                    <NavLink className="text-light mb-2" to="/visa/student"><i className="bi bi-arrow-right text-primary me-2"></i>Students Visa</NavLink>
                                    <NavLink className="text-light mb-2" to="/visa/work"><i className="bi bi-arrow-right text-primary me-2"></i>Work Permit</NavLink>
                                    <NavLink className="text-light mb-2" to="/visa/business"><i className="bi bi-arrow-right text-primary me-2"></i>Business Visa</NavLink>
                                    <NavLink className="text-light mb-2" to="/immigration/pnp"><i className="bi bi-arrow-right text-primary me-2"></i>Provincial Nomination</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid text-white" style={{ background: '#061429' }}>
            <div className="container text-center">
                <div className="row justify-content-end">
                    <div className="col-lg-8 col-md-6">
                        <div className="d-flex align-items-center justify-content-center" style={{ height: 75 }}>
                            <p className="mb-0">&copy; <a className="text-white border-bottom" href="#">{appName}</a>. All Rights Reserved.

                                {/* <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. **--> */}
                                {/* Designed by <a className="text-white border-bottom" href="https://htmlcodex.com">HTML Codex</a> */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Footer;