import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AdminService } from '../../services/adminService';


const ResetPassword = (props) => {
    let [message, setMessage] = useState('');
    let [resetFlow, setResetFlow] = useState(false);
    const params = useParams();
    useEffect(() => {
        if (params && params.id) {
            setResetFlow(true);
        }
    }, [])
    const resetPassword = (event) => {
        event.preventDefault();
        const form = document.forms['resetPwdForm'];
        const data = {
            username: form.username.value,
            email: form.email.value
        };
        AdminService.resetPassword(data).then(res => {
            if (res && res.data) {
                if (res.data.success) {
                    form.reset();
                }
                setMessage(res.data.message);
            }
        })
    };


    const resetPasswordConfirm = (event) => {
        event.preventDefault();
        const form = document.forms['resetPwdForm'];
        const data = {
            password: form.password.value,
            confirmPwd: form.confirmpassword.value
        };
        if (data.password != data.confirmPwd) {
            setMessage('Password and Confirm Password doesn\'t match.');
            return;
        }
        data.code = params.id;
        delete data.confirmPwd;
        AdminService.resetPasswordConfirm(data).then(res => {
            if (res && res.data) {
                if (res.data.success) {
                    form.reset();
                }
                setMessage(res.data.message);
            }
        })
    }

    return (<>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container pb-5">
                <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                    <h5 class="fw-bold text-primary text-uppercase">Reset Password</h5>
                </div>
                {resetFlow ?
                    <div class="row g-5 mb-5">
                        <div className='col-sm-12 col-md-4 offset-md-4 p-4 border'>
                            <form onSubmit={resetPasswordConfirm} name="resetPwdForm">
                                <div class="form-floating mb-3">
                                    <input type="password" required class="form-control" id="floatingInput" placeholder="" name="password" />
                                    <label for="floatingInput">Password</label>
                                </div>
                                <div class="form-floating">
                                    <input type="password" required class="form-control" id="floatingEmail" placeholder="" name="confirmpassword" />
                                    <label for="floatingEmail">Confirm Password</label>
                                </div>
                                <div className='d-flex justify-content-between mt-3'>
                                        <Link to="/login">Login</Link>
                                    
                                        <button type="submit" class="btn btn-primary">Reset</button>
                                </div>
                                {message ? <div className='alert-danger p-2 mt-3'>
                                    <p className='text-danger m-0'>{message}</p>
                                </div> : null}
                            </form>
                        </div>
                    </div>
                    :
                    <div class="row g-5 mb-5">
                        <div className='col-sm-12 col-md-4 offset-md-4 p-4 border'>
                            <form onSubmit={resetPassword} name="resetPwdForm">
                                <div class="form-floating mb-3">
                                    <input type="text" required class="form-control" id="floatingInput" placeholder="" name="username" />
                                    <label for="floatingInput">Username</label>
                                </div>
                                <div class="form-floating">
                                    <input type="email" required class="form-control" id="floatingEmail" placeholder="" name="email" />
                                    <label for="floatingEmail">Email</label>
                                </div>
                                <div className='float-end mt-3'>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </div>
                                {message ? <div className='mt-3'>
                                    <p className='text-danger'>{message}</p>
                                </div> : null}
                            </form>
                        </div>
                    </div>}
            </div>
        </div>
    </>);
}

export default ResetPassword;