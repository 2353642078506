import React from 'react';
import Accordion from '../../accordion/Accordion';

const Business = () => {
    const accordionData = {
        title: 'Frequently asked Questions',
        data: [
            { title: 'How much money is needed to obtain a business visa for Canada?', description: 'An approved angel investor group attesting to its investment of at least $75,000 in an eligible company, or two or more approved angel investor groups attesting to their combined investment of at least $75,000 in such a company.' },
            { title: 'What is the cost structure for applying for a visa?', description: 'It differs according to the nation. It’s hard to say.' },
            { title: 'Does a business visa to Canada need an interview?', description: 'You will be required to participate in two screening interviews once you get to Canada. If all of your documents are ready and organized, they ought to go by soon.' }
        ]
    };
    return (<>
        <div className="mb-5">
            <img class="img-fluid w-100 rounded mb-5" src="/assets/img/business.jpg" alt="" />
            <h3 className='mb-3'>
                Would you like to establish a business and relocate to Canada? Should you possess commercial acumen or pertinent experience, you can be qualified for immigration to Canada.
            </h3>
            <p>Based on their capacity to establish themselves economically and contribute to the growth of the Canadian economy, business class immigrants are chosen by the government of Canada. The entire family is eligible to immigrate to Canada as a permanent residence under the business class. The governments of Canada, both federal and provincial/territorial, welcome business immigrants and provide resources to assist them in establishing a company and relocating. It is expected of business immigrants to contribute to the growth of a robust and affluent Canadian economy by starting or investing in firms in Canada. The Federal Business Immigrant Program is divided into two classes: startup visa Individuals working by themselves There are numerous provincial nominee programs for business class in addition to federal ones. Candidates for the business class are chosen primarily based on their capacity to produce</p>
            <div className="row g-5 mb-3">
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/business/1.png' />
                        <h6 className="mb-3-invalid">Complete The Essential Document</h6>
                        <p className="m-0">Filling out the necessary form is necessary in order to apply for a Canadian visa.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/business/2.png' />
                        <h6 className="mb-3-invalid">Submit Your Original Documents</h6>
                        <p className="m-0">To obtain the visa on time, you must submit the original document along with other information.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/business/3.png' />
                        <h6 className="mb-3-invalid">Obtain The Visa Reference Guide</h6>
                        <p className="m-0">You will receive your resource materials for a visa in a few days after finishing all the activities.</p>
                    </div>
                </div>
            </div>
            <h3 className='mb-3'>
                Benefits of a Business Visa
            </h3>
            <ul className='immigration-list'>
                <li><i className='bi bi-arrow-right-circle'></i> To the most recent photo that was taken for an insurance policy</li>
                <li><i className='bi bi-arrow-right-circle'></i> Experience verification needs to be included.</li>
                <li><i className='bi bi-arrow-right-circle'></i> A passport that is currently valid</li>
                <li><i className='bi bi-arrow-right-circle'></i> Travel</li>
                <li><i className='bi bi-arrow-right-circle'></i> Documentation of Income</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of paid visa application fees</li>
            </ul>
            <h3 className='mb-3'>
                Who Is Eligible to File a Business Visa?
            </h3>
            <p>firm professionals with strong ties to running a firm and a willingness to participate in the Canadian economy are eligible to apply for the Business Investor program. The main criterion used to choose applicants for business class positions is their capacity to generate employment for both themselves and other Canadians.</p>
            <Accordion data={accordionData.data} title={accordionData.title} uniqueId='businessVisaAcc' />
        </div>
    </>);
}

export default Business;