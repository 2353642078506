import React from 'react';
import './ExpressEntry.css';
import { appName } from '../../../config/config';
import Accordion from '../../accordion/Accordion';

const ExpressEntry = () => {
    const accordionData = {
        title: 'Frequently asked Questions',
        data: [
            {title:'How much time does it take to complete an Express Entry?', description:'The average processing time for an express entry file is six months from the date of submission.'},
            {title:'What paperwork is needed in order to use Express Entry?', description:'It differs according to the nation. It’s hard to say. However, '+appName+' can assist you; please get in touch for more information about the procedure.'},
            {title:'What are the essential details for an applicant for Express Entry to be aware of?', description:'In order to submit your application for processing, you will need to pay Immigration Canada and provide information about yourself. You should also prepare your educational records and note that submitting the profile is free of charge.'}
        ]
    };

    return (<>
        <div class="mb-5">
            <img class="d-none img-fluid w-100 rounded mb-5" src="/assets/img/express-entry.jpg" alt="" />
            <p>The new Express Entry system was unveiled in January 2015 to assist Citizenship and Immigration Canada (CIC) in choosing permanent residents who can contribute to economic growth through their qualifications and expertise. It’s an online two-step method that uses a ranking system.</p>
            <p>The applicant must first register online and join the pool to compete with other candidates in order to be eligible for the Express Entry stream. Subsequently, the system evaluates the candidate on the basis of their age, education, employment history, language proficiency, and marital status, among other variables.</p>
            <p>The system creates a score when the profile is generated based on the data and documents that are entered. Enhancing one’s language skills or having a prearranged job offer from a Canadian firm can boost an applicant’s score.</p>
            <p><b>Note:</b> The candidates stay in the pool for a maximum of 12 months.</p>
            <p>The candidate must build a new profile if they do not receive an invitation during the allotted 12-month timeframe.</p>
            <h3 className='mb-3'>
                Three Programs of Express Entry
            </h3>
            <p>Express Entry is Canada’s system for managing applications for permanent residence through various immigration programs. It is designed to facilitate the entry of skilled workers into Canada. There are three main programs within Express Entry:</p>
            <div className="row g-5 mb-3">
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="express service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/express/FSWP.png' />
                        <h6 className="mb-3-invalid">Federal Skilled Worker (FSWP)</h6>
                        <p className="m-0">This program is aimed at skilled workers with foreign work experience</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="express service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/express/FSTP.png' />
                        <h6 className="mb-3-invalid">Federal Skilled Trades (FSTP)</h6>
                        <p className="m-0">This program targets skilled trade people with work experience in specific trade occupations.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="express service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/express/CEC.png' />
                        <h6 className="mb-3-invalid">Canadian Experience Class</h6>
                        <p className="m-0">This program is designed for individuals who have gained skilled work experience in Canada</p>
                    </div>
                </div>
            </div>
            <h3 className='mb-3'>Express Entry Benefits</h3>
            <ul className='immigration-list'>
                <li><i className='bi bi-arrow-right-circle'></i> You must submit the most current snapshot you took.</li>
                <li><i className='bi bi-arrow-right-circle'></i> A valid passport</li>
                <li><i className='bi bi-arrow-right-circle'></i> Documentation of education</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of finances</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of paid visa application fees</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of professional experience.</li>
            </ul>
            <h3 className='mb-3'>How to register for Express Entry</h3>
            <p>The applicant must first register online and join the pool to compete with other candidates in order to be eligible for the Express Entry stream. Subsequently, the system evaluates the candidate on the basis of their age, education, employment history, language proficiency, and marital status, among other variables. The system creates a score when the profile is generated based on the data and documents that are entered. Enhancing one’s language proficiency or having a prearranged job offer from a Canadian firm can boost an applicant’s score.</p>
            <Accordion data={accordionData.data} title={accordionData.title} uniqueId='expressentryAccc'/>
        </div>
    </>);
}

export default ExpressEntry;