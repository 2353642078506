import axios from 'axios';

export class Networker {

    static toggleSpinner(show) {
        if (show)
            document.getElementById('spinner').classList.add('show');
        else
            document.getElementById('spinner').classList.remove('show');
    }
    static get(url, showspinner = true) {
        if(showspinner)
            this.toggleSpinner(true);
        return axios.get(url).catch(()=>{}).finally(()=>{
            this.toggleSpinner(false);
        });
    }
    static post(url, data, showspinner = true) {
        if(showspinner)
            this.toggleSpinner(true);
        return axios.post(url, data).catch(()=>{}).finally(()=>{
            this.toggleSpinner(false);
        });
    }
    static delete(url, showspinner = true) {
        if(showspinner)
            this.toggleSpinner(true);
        return axios.delete(url).catch(()=>{}).finally(()=>{
            this.toggleSpinner(false);
        });
    }
}