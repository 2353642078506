import React from 'react';

const Accordion = ({ title, data, uniqueId }) => {
    return (<>
        {title ? <h3 class="mb-3">{title}</h3> : null}
        <div class="accordion" id={uniqueId}>
            {data.map((acc, index)=>{
                return <div key={'key'+uniqueId+index} class="accordion-item">
                <h2 class="accordion-header" id={'heading'+uniqueId+index}>
                    <button class={"accordion-button bg-light text-dark " + (index == 0 ? "" : "collapsed")} type="button" data-bs-toggle="collapse" data-bs-target={'#collapse'+uniqueId+index} aria-expanded="true" aria-controls={'collapse'+uniqueId+index}>
                        {acc.title}
                    </button>
                </h2>
                <div id={'collapse'+uniqueId+index} class={"accordion-collapse collapse " + (index == 0 ? "show" : "")} aria-labelledby={'heading'+uniqueId+index} data-bs-parent={'#'+uniqueId}>
                    <div class="accordion-body">
                        {acc.description}
                    </div>
                </div>
            </div>
            })}
        </div>
    </>);
}

export default Accordion;