import React, { useState } from 'react';
import { phone, phoneDisplay } from '../../config/config';
import { EmailService } from '../../services/emailService';

const ContactSection = () => {
    let [message, setMessage] = useState('');

    const sendEmail= (e) => {
        e.preventDefault();
        setMessage('');
        const form = document.forms['contactus'];
        EmailService.sendEmail(form.name.value, form.phone.value, form.email.value, form.reason.value, "", form.message.value).then(res=>{
            setMessage('Thank you for contacting us. We will get back to you shortly!');
            form.reset();
        }).catch(res=>{
            setMessage('An error occurred, please try again later!');
        })
    }

    return ( <>
    <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-lg-7">
                    <div class="section-title position-relative pb-3 mb-5">
                        <h5 class="fw-bold text-primary text-uppercase">Contact Us</h5>
                        <h1 class="mb-0">Have a Query? Please Feel Free to Contact Us</h1>
                    </div>
                    <div class="row gx-3">
                        <div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                            <h5 class="mb-4"><i class="fa fa-reply text-primary me-3"></i>Reply within 24 hours</h5>
                        </div>
                        <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                            <h5 class="mb-4"><i class="fa fa-phone-alt text-primary me-3"></i>24 hrs telephone support</h5>
                        </div>
                    </div>
                    <p class="mb-4 text-justify">We’re here to help you every step of the way. Whether you have questions, need more information, or are ready to start your immigration journey, our team is just a message or call away.</p>
                    <div class="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.6s">
                        <div class="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: 60, height: 60}}>
                            <i class="fa fa-phone-alt text-white"></i>
                        </div>
                        <div class="ps-4">
                            <h5 class="mb-2">Call to ask any question</h5>
                            <a href={'tel:'+phone}><h4 class="text-primary mb-0">{phoneDisplay}</h4></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
                        <form onSubmit={sendEmail} name="contactus">
                            <div class="row g-3">
                                <div class="col-6">
                                    <input name="name" type="text" class="form-control bg-light border-0" placeholder="Your Name" style={{height: 55}} />
                                </div>
                                <div class="col-6">
                                    <input name="email" type="email" class="form-control bg-light border-0" placeholder="Your Email" style={{height: 55}} />
                                </div>
                                <div class="col-6">
                                    <input name="phone" type="number" class="form-control bg-light border-0" placeholder="Your Phone" style={{height: 55}} />
                                </div>
                                <div class="col-6">
                                    <select name="reason" class="form-select bg-light border-0" style={{height: 55}}>
                                        <option value="General Inquiry" selected>Select A Reason</option>
                                        <option value="Visiting">Visiting</option>
                                        <option value="Student">Student</option>
                                        <option value="Work">Work</option>
                                        <option value="PNP">PNP</option>
                                        <option value="Spousal">Spousal</option>
                                        <option value="General Inquiry">General Inquiry</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <textarea name="message" class="form-control bg-light border-0" rows="3" placeholder="Message"></textarea>
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-dark w-100 py-3" type="submit">Submit Query</button>
                                </div>
                                {message ? <div className='col-12'>
                                    <p className='text-white'>{message}</p>
                                </div>: null}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </> );
}
 
export default ContactSection;