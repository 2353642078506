import urls from "../config/urls";
import { Networker } from "./networker";

export class AdminService {
    static LoginUser(username, password){
        return Networker.post(urls.login, {username, password});
    }

    static uploadFile(event) {
        if (event.target.files.length > 0) {
            let formData = new FormData();
            for (var i = 0; i < event.target.files.length; i++) {
                formData.append("files", event.target.files[i]);
            }
            return Networker.post(urls.upload, formData);
        }
    }

    static updateAchievement(data) {
        return Networker.post(urls.updateAchievement, data);
    }

    static getAchievements(showspinner = true) {
        return Networker.get(urls.getAchievements, showspinner);
    }

    static updateFeedback(data, showspinner = true) {
        return Networker.post(urls.updateFeedback, data, showspinner);
    }

    static getFeedbacks(showspinner = true) {
        return Networker.get(urls.getFeedbacks, showspinner);
    }

    static DeleteAchievement(id, showspinner = true) {
        return Networker.delete(urls.deleteAchievement+'?id='+id, showspinner);
    }

    static DeleteFeedback(id, showspinner = true) {
        return Networker.delete(urls.deleteFeedback+'?id='+id, showspinner);
    }

    static logout() {
        return Networker.get(urls.logoutUser);
    }

    static loggedInUser() {
        return Networker.get(urls.userDetails);
    }
    static resetPassword(data) {
        return Networker.post(urls.resetPassword, data);
    }
    static resetPasswordConfirm(data) {
        return Networker.post(urls.resetPasswordConfirm, data);
    }
}