import React from 'react';
import Accordion from '../../accordion/Accordion';

const Visitor = () => {
    const accordionData = {
        title: 'Frequently asked Questions',
        data: [
            { title: 'What is the duration of processing for a visitor\'s visa?', description: 'Three months is the average file for a visitor, though this also depends on the nation in which you are applying.' },
            { title: 'Who in Canada may sponsor me?', description: 'You must be a Canadian citizen, a person registered as an Indian under the Canadian Indian Act, or a permanent resident of Canada in order to apply to be a sponsor. You must also be at least eighteen years old.' },
            { title: 'What is the process for sending someone an invitation to Canada?', description: 'Full name, birthdate, address, phone number, relationship to the individual, reason for travel, anticipated length of stay in Canada, intended location, and method of payment for items' }
        ]
    };
    return (<>
        <div className="mb-5">
            <img class="img-fluid w-100 rounded mb-5" src="/assets/img/visitor.png" alt="" />
            <p>Visitors to Canada are permitted to enter the country for a variety of reasons, one of which is to visit or see family. Certain countries do not require a visa to enter Canada, but others do require permission to enter and remain for a set amount of time. It is known as a Visa for Temporary Residency.</p>
            <h3 className='mb-3'>
                Visitor Visa
            </h3>
            <ul className='immigration-list'>
                <li><i className='bi bi-arrow-right-circle'></i> You must submit the most current snapshot you took.</li>
                <li><i className='bi bi-arrow-right-circle'></i> A current passport </li>
                <li><i className='bi bi-arrow-right-circle'></i> Certificate of health</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of financial resources</li>
                <li><i className='bi bi-arrow-right-circle'></i> Dedicated to One's Home Country</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence that, at completion of your permitted stay, you will depart Canada</li>
            </ul>
            <Accordion data={accordionData.data} title={accordionData.title} uniqueId='visitorVisaAcc' />
        </div>
    </>);
}

export default Visitor;