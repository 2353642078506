import React from 'react';

const DetailsSection = ({ details }) => {
    // const getElement = (ele) => {

    //     switch(ele.name) {
    //         case "p":
    //             return <p className={ele.class || ''}>{ele.content}</p>;
    //         case "h1"
    //     }
    // }
    return (<>
        {details.map((ele, i) => {
            return React.createElement(ele.type, ele.props, ele.children)
        })}
    </>);
}

export default DetailsSection;