import React, { useState } from 'react';
import { AdminService } from '../../services/adminService';

const Feedback = () => {
    let [message, setMessage] = useState('');
    const submitFeedback = (event) => {
        setMessage('');
        event.preventDefault();
        const form = document.forms['feedbackform'];
        const data = {
            name: form.name.value,
            category: form.category.value,
            feedback: form.feedback.value,
            //phone: form.phone.value,
            phone: '',
            email: form.email.value,
        };
        AdminService.updateFeedback(data).then(res => {
            if (res && res.data && res.data.message) {
                setMessage(res.data.message);
                form.reset();
            }
        });
    }
    return (<>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container py-5">
                <div class="row g-5">
                    <div class="col-lg-7">
                        <div class="section-title position-relative pb-3 mb-5">
                            <h5 class="fw-bold text-primary text-uppercase">Feedback</h5>
                            <h1 class="mb-0">We Value Your Feedback!</h1>
                        </div>
                        <p className=' text-justify'>Your thoughts and experiences are important to us. Whether you have suggestions, questions, or just want to share your experience, we’re here to listen. Your feedback helps us improve and provide you with the best possible service. Please take a moment to let us know how we’re doing. Thank you for helping us grow!</p>
                    </div>
                    <div class="col-lg-5">
                        <div class="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
                            <form onSubmit={submitFeedback} name="feedbackform">
                                <div class="row g-3">
                                    <div class="col-6">
                                        <input name="name" required type="text" class="form-control bg-light border-0" placeholder="Name" style={{ height: 55 }} />
                                    </div>
                                    <div class="col-6">
                                        <input name="category" required type="text" class="form-control bg-light border-0" placeholder="Category" style={{ height: 55 }} />
                                    </div>
                                    <div class="col-md-12">
                                        <input name="email" type="email" required class="form-control bg-light border-0" placeholder="Your Email" style={{ height: 55 }} />
                                    </div>
                                    <div class="col-12">
                                        <textarea name="feedback" required class="form-control bg-light border-0" rows="3" placeholder="Feedback"></textarea>
                                    </div>
                                    <div class="col-12">
                                        <button class="btn btn-dark w-100 py-3" type="submit">Submit Feedback</button>
                                    </div>
                                    {message ? <div class="col-12">
                                        <p className='text-white'>{message}</p>
                                    </div> : null}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Feedback;