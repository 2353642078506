import React, { useEffect, useState } from 'react';
import { AdminService } from '../../services/adminService';

const TestimonialSection = () => {

    let [feedbacks, setFeedbacks] = useState([]);
    const getFeedbacks = () => {
        AdminService.getFeedbacks(false).then(res=>{
            if(res && res.data && res.data.data) {
                setFeedbacks(res.data.data.filter(x=>x.isEnabled));
                updateCarousel();
            }
        }).catch(res=>{});
    }

    const updateCarousel = ()=>{
        setTimeout(()=>{
            window.$(".testimonial-carousel").owlCarousel({
                autoplay: true,
                smartSpeed: 1500,
                dots: true,
                loop: true,
                center: true,
                responsive: {
                    0:{
                        items:1
                    },
                    576:{
                        items:1
                    },
                    768:{
                        items:2
                    },
                    992:{
                        items:3
                    }
                }
            });
        }, 100);
    }

    useEffect(()=>{
        getFeedbacks();
    }, [])
    return ( feedbacks.length ? <>
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-4 mx-auto" style={{maxWidth: 600}}>
                <h5 className="fw-bold text-primary text-uppercase">Testimonial</h5>
                <h1 className="mb-0">What Our Clients Say About Our Services</h1>
            </div>
            <div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.6s">
                {feedbacks.map((fb, ind)=>{
                    return <div key={'feedbk'+ind} className="testimonial-item bg-light my-4">
                    <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                        <img className="img-fluid rounded" src="/assets/img/client.png" style={{width: 60, height: 60}} />
                        <div className="ps-4">
                            <h4 className="text-primary mb-1">{fb.name}</h4>
                            <small className="text-uppercase">{fb.category}</small>
                        </div>
                    </div>
                    <div className="pt-4 pb-5 px-5 text-justify">
                        {fb.feedback}
                    </div>
                </div>
                })}
            </div>
        </div>
    </div>
    </> : null );
}
 
export default TestimonialSection;