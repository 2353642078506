import React from 'react';
import './FamilySponsorship.css';
import { appName } from '../../../config/config';
import Accordion from '../../accordion/Accordion';

const FamilySponsorship = () => {
    const accordionData = {
        title: 'Frequently asked Questions',
        data: [
            {title:'How can I sponsor a relative to come to Canada?', description:'To find out how to sponsor family members, get in touch with ' + appName + '.'},
            {title:'What is the price of sponsoring a family member to come to Canada?', description:'Encourage your grandparents or parents to Fees for the sponsorship ($75), processing of the primary application ($490), and the right to permanent residence ($515)'},
            {title:'What qualifies as relationship proof?', description:'It is necessary to submit copies of the family member’s birth certificate, permanent resident card, and/or Canadian citizenship paperwork on both sides. There must be proof that the family member is presently residing in Canada, such as a utility bill, home lease, or mortgage documents.'}
        ]
    };
    return (<>
        <div class="mb-5">
            <img class="img-fluid w-100 rounded mb-5" src="/assets/img/family-sponsorship.jpg" alt="" />
            <p>Either the Super Visa program or Family Class sponsorship is a popular scheme that allows Canadian citizens and permanent residents to bring their parents and grandparents to Canada.</p>
            <p>A stream is available for parents and grandparents of Canadian citizens and permanent residents under the Family Class sponsorship program. Parents and grandparents who successfully complete this program will be granted permanent residence in Canada and may be eligible to apply for citizenship after four years.</p>
            <h3 class="mb-3">Qualified for sponsorship in the Family Class</h3>
            <ul className='immigration-list'>
                <li><i className='bi bi-arrow-right-circle'></i> Must be a Canadian citizen or permanent resident.</li>
                <li><i className='bi bi-arrow-right-circle'></i> Must be at least 18 years old.</li>
                <li><i className='bi bi-arrow-right-circle'></i> Must meet or exceed the minimum income level required for this program.</li>
                <li><i className='bi bi-arrow-right-circle'></i> Provide proof of visa fee payment.</li>
                <li><i className='bi bi-arrow-right-circle'></i> Submit proof of relationship.</li>
            </ul>
            <p>
                Sponsors must demonstrate that they meet the minimum income requirements by submitting Notices of Assessment from the Canadian Revenue Agency (CRA) to support their sponsorship. Additionally, they must show that they have met the minimum income level for three consecutive years. If you meet these requirements or have any questions, please contact us for more detailed information. Once your profile is created, the system generates a score based on the provided information and documents. This score can be enhanced if the applicant has a job offer from a Canadian employer or by improving their language skills.
            </p>
            {/* <img class="img-fluid w-100 rounded mb-5" src="/assets/img/blog-1.jpg" alt="" />
            <h1 class="mb-4">Diam dolor est labore duo ipsum clita sed et lorem tempor duo</h1>*/}
            <Accordion data={accordionData.data} title={accordionData.title} uniqueId='familyAcc'/>
        </div>
    </>);
}

export default FamilySponsorship;