import React from 'react';
import DetailsSection from '../../details-section/DetailsSection';
import Accordion from '../../accordion/Accordion';

const Student = () => {
    const accordionData = {
        title: 'Frequently asked Questions',
        data: [
            { title: 'How much time does it take to process a student visa?', description: 'The length of time it takes to apply for a student visa varies depending on the applicant and the situation. In simpler cases, the complete process may take only one week, while in more complex cases, it may take many months.' },
            { title: 'What is the cost structure for applying for a visa?', description: 'Depending on the college, it changes. It’s hard to say.' },
            { title: 'What information is crucial to have while applying for a student visa?', description: 'You ought to be aware of the specifics of versity and its price. Is it feasible to work while attending college and a few other things?' }
        ]
    };
    const details1 = [
        {
            type: 'img',
            props: {
                className: 'img-fluid w-100 rounded mb-5',
                src: '/assets/img/student.png'
            },
            children: null
        },
        {
            type: 'h3',
            props: {
                className: 'mb-3'
            },
            children: 'Over 100,000 students come to study in Canada every year.'
        },
        {
            type: 'p',
            props: {},
            children: 'One of the greatest educational systems in the world is found in Canada. All interests and backgrounds are catered to in Canadian schools, which offer everything from language instruction to university-level education, as well as vocational programs.'
        },
        {
            type: 'p',
            props: {},
            children: 'Studying in Canada equips students for future career endeavors with a high-quality, globally recognized education. This is paired with living expenses and tuition costs that are often lower than in other wealthy nations.'
        },
        {
            type: 'p',
            props: {},
            children: 'Over 700,000 overseas students are enrolled in Canadian universities, and the number is rising steadily. Look through our service areas to see if you qualify now to find out more about how you can join them.'
        }
    ]
    return (<>
        <div className="mb-5">
            <DetailsSection details={details1} />
            <div className="row g-5 mb-3">
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/student/1.png' />
                        <h6 className="mb-3-invalid">Appointed Educational Institution</h6>
                        <p className="m-0">You should only enroll in designated learning institutions in order to apply for a Canadian student visa.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/student/2.png' />
                        <h6 className="mb-3-invalid">Present Conditions in Canada</h6>
                        <p className="m-0">To obtain the visa on time, you must submit the original document along with other information.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/student/3.png' />
                        <h6 className="mb-3-invalid">Evidence of Monetary Support</h6>
                        <p className="m-0">You want to have the money to cover living expenses in Canada as well as the cost of your education.</p>
                    </div>
                </div>
            </div>
            <h3 className='mb-3'>
            Need for Student Visa
            </h3>
            <ul className='immigration-list'>
                <li><i className='bi bi-arrow-right-circle'></i> Admitted with success to a Canadian Designated Learning Institution (DLI)</li>
                <li><i className='bi bi-arrow-right-circle'></i> A passport that is valid</li>
                <li><i className='bi bi-arrow-right-circle'></i> Make a vow to depart Canada after completing your school.</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of financial capability</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of paid visa application fees</li>
            </ul>
            <h3 className='mb-3'>How to Submit an Application for Citizenship</h3>
            <p>After completing their studies, students can apply to become permanent residents of Canada. Kindly refer to the website’s “Immigrate” section for details on obtaining permanent residency in Canada.</p>
            <Accordion data={accordionData.data} title={accordionData.title} uniqueId='studentVisaAcc' />
        </div>
    </>);
}

export default Student;