export class HelperService {
    static formatDisplayDate = (date) => {
        var mydate = new Date(date);
        var month = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"][mydate.getMonth()];
        var str = month + ' ' + mydate.getDate() + ', ' + mydate.getFullYear();
        return str;
    };

    static formatInputDate = (date) => {
        let month = date.getMonth()+1;
        if(month < 10) {
            month = '0'+month;
        }

        let day = date.getDate();
        if(day < 10) {
            day = '0'+day;
        }

        var str = date.getFullYear() +'-'+ month +'-'+ day;
        return str;
    };

    static calculateCRSScore(applicant) {
        let score = 0;
    
        // Age points
        const agePoints = {
            "18-35": 12,
            "36": 11,
            "37": 10,
            "38": 9,
            "39": 8,
            "40": 7,
            "41": 6,
            "42": 5,
            "43": 4,
            "44": 3,
            "45": 2,
            "46": 1,
            "47+": 0
        };
        score += agePoints[applicant.age] || 0;
    
        // Education points
        const educationPoints = {
            "PhD": 25,
            "Masters": 23,
            "Two or more post-secondary degrees": 22,
            "Bachelor's degree": 21,
            "Post-secondary diploma": 19,
            "Secondary diploma": 5
        };
        score += educationPoints[applicant.education] || 0;
    
        // Language proficiency points
        const languagePoints = {
            "CLB 9+": 24,
            "CLB 8": 22,
            "CLB 7": 20,
            "CLB 6": 16,
            "CLB 5": 10,
            "CLB 4": 6,
            "Below CLB 4": 0
        };
        score += languagePoints[applicant.language] || 0;
    
        // Work experience points
        const workExperiencePoints = {
            "5 years or more": 15,
            "4 years": 13,
            "3 years": 11,
            "2 years": 9,
            "1 year": 7,
            "Less than 1 year": 0
        };
        score += workExperiencePoints[applicant.workExperience] || 0;
    
        // Additional points for job offer or provincial nomination
        if (applicant.jobOffer) {
            score += 50;
        }
        if (applicant.provincialNomination) {
            score += 600;
        }
    
        return score;
    }
    
    // Example usage
    // const applicant = {
    //     age: "30",
    //     education: "Masters",
    //     language: "CLB 9+",
    //     workExperience: "5 years or more",
    //     jobOffer: true,
    //     provincialNomination: false
    // };
    
    // console.log("CRS Score:", calculateCRSScore(applicant));
}