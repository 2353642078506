import React from 'react';
import './ServiceSection.css';
import { phone, phoneDisplay } from '../../config/config';
import Success from '../success/Success';
import { Link } from 'react-router-dom';

const ServiceSection = () => {
    return (<>

        <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                    <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
                    <h1 className="mb-0">Our Comprehensive Range of Services Includes</h1>
                </div>
                <div className="row g-5">
                    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                        <div className="team-item bg-light rounded overflow-hidden">
                            <div className="team-img position-relative overflow-hidden">
                                <img className="img-fluid w-100" src="/assets/img/work.jpg" alt="" />
                                {/* <div className="team-social">
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                            </div> */}
                            </div>
                            <div className="text-center py-4">
                                <h4 className="text-primary">Work in Canada</h4>
                                <p className="m-0">Unlock endless opportunities and experience a vibrant, multicultural life by working in Canada!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                        <div className="team-item bg-light rounded overflow-hidden">
                            <div className="team-img position-relative overflow-hidden">
                                <img className="img-fluid w-100" src="/assets/img/immigrate.jpg" alt="" />
                                {/* <div className="team-social">
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                            </div> */}
                            </div>
                            <div className="text-center py-4">
                                <h4 className="text-primary">Immigrate to Canada</h4>
                                <p className="m-0">Embrace a new beginning in a welcoming, diverse, and opportunity-rich country by immigrating to Canada!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                        <div className="team-item bg-light rounded overflow-hidden">
                            <div className="team-img position-relative overflow-hidden">
                                <img className="img-fluid w-100" src="/assets/img/study.jpg" alt="" />
                                {/* <div className="team-social">
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                            </div> */}
                            </div>
                            <div className="text-center py-4">
                                <h4 className="text-primary">Study in Canada</h4>
                                <p className="m-0">Transform your future with a world-class education and unforgettable experiences by studying in Canada!</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container py-5">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                        {/* <h5 className="fw-bold text-primary text-uppercase">Our Services</h5> */}
                        <h1 className="mb-0">Other Areas of Service</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <img className='service-img mb-3' src='/assets/img/services/visitor.png' />
                                <h4 className="mb-3-invalid">Visitor Visa</h4>
                                <p className="m-0 d-none">description</p>
                                <Link className="btn btn-lg btn-primary rounded" to="/visa/visitor">
                                    <i className="bi bi-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <img className='service-img mb-3' src='/assets/img/services/study.png' />
                                <h4 className="mb-3-invalid">Study Visa</h4>
                                <p className="m-0 d-none">description</p>
                                <Link className="btn btn-lg btn-primary rounded" to="/visa/student">
                                    <i className="bi bi-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <img className='service-img mb-3' src='/assets/img/services/work.png' />
                                <h4 className="mb-3-invalid">Work Permit</h4>
                                <p className="m-0 d-none">description</p>
                                <Link className="btn btn-lg btn-primary rounded" to="/visa/work">
                                    <i className="bi bi-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <img className='service-img mb-3' src='/assets/img/services/spousal.png' />
                                <h4 className="mb-3-invalid">Spousal Visa</h4>
                                <p className="m-0 d-none">description</p>
                                <Link className="btn btn-lg btn-primary rounded" to="/immigration/spouse">
                                    <i className="bi bi-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <img className='service-img mb-3' src='/assets/img/services/caregiver.png' />
                                <h4 className="mb-3-invalid">Live In Caregiver</h4>
                                <p className="m-0 d-none">description</p>
                                <Link className="btn btn-lg btn-primary rounded" to="/visa/caregiver">
                                    <i className="bi bi-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <img className='service-img mb-3' src='/assets/img/services/PR.png' />
                                <h4 className="mb-3-invalid">Permenant Residency</h4>
                                <p className="m-0 d-none">description</p>
                                <Link className="btn btn-lg btn-primary rounded" to="/immigration/expressentry">
                                    <i className="bi bi-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <img className='service-img mb-3' src='/assets/img/services/BC-PNP.png' />
                                <h4 className="mb-3-invalid">BC PNP</h4>
                                <p className="m-0 d-none">description</p>
                                <Link className="btn btn-lg btn-primary rounded" to="/immigration/pnp">
                                    <i className="bi bi-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <img className='service-img mb-3' src='/assets/img/services/super-visa.png' />
                                <h4 className="mb-3-invalid">Super visa for parents and grandparents</h4>
                                <p className="m-0 d-none">description</p>
                                <Link className="btn btn-lg btn-primary rounded" to="/visa/super">
                                    <i className="bi bi-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <img className='service-img mb-3' src='/assets/img/services/HNC.png' />
                                <h4 className="mb-3-invalid">Humanitarian and compassionate grounds</h4>
                                <p className="m-0 d-none">description</p>
                                <Link className="btn btn-lg btn-primary rounded" to="/immigration/hnc">
                                    <i className="bi bi-arrow-right"></i>
                                </Link>
                            </div>
                        </div>

                        
                        <div className="col-lg-4 col-md-6 offset-lg-4 wow zoomIn" data-wow-delay="0.9s">
                            <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
                                <h3 className="text-white mb-3">Call Us For Help</h3>
                                <p className="d-none text-white mb-3">Clita ipsum magna kasd rebum at ipsum amet dolor justo dolor est magna stet eirmod</p>
                                <a href={'tel:'+phone}><h2 className="text-white mb-0">{phoneDisplay}</h2></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default ServiceSection;