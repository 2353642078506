import React from 'react';

const HnC = () => {
    return (<>
        <div className="mb-5">
            <img class="img-fluid w-100 rounded mb-5" src="/assets/img/HnC.jpg" alt="" />
            <p>Under exceptional circumstances, those who would not typically be eligible to become permanent residents of Canada may be permitted to apply on the basis of compassion and humanitarianism. People with unusual cases are entitled to consideration and sympathy. These applications are evaluated individually for each one. The degree of a person’s settlement in Canada is one of the factors taken into account. general family links to Canada, the children’s best interests, and your potential consequences should you be denied permanent residence in Canada</p>
            <h3 className='mb-3'>
                Qualifications for Compassionate and Humanitarian Ground
            </h3>
            <ul className='immigration-list'>
                <li><i className='bi bi-arrow-right-circle'></i> are a foreign national residing in Canada at the moment.</li>
                <li><i className='bi bi-arrow-right-circle'></i> require a waiver of one or more Immigration and Refugee Protection Act (IRPA) restrictions</li>
                <li><i className='bi bi-arrow-right-circle'></i> feel that giving you the necessary exemption(s) is justified by humanitarian and compassionate reasons "AND:</li>
                <li><i className='bi bi-arrow-right-circle'></i> are not qualified to apply for permanent residence in any of these classes from within Canada.</li>
            </ul>
            <p>This class is for those whose circumstances call for humanitarian and compassionate considerations but who do not meet the ordinary requirements of the Immigration Act and regulations. If you think you should be taken into consideration for this category, please talk with us.</p>
        </div>
    </>);
}

export default HnC;