import React, { useEffect, useState } from 'react';
import BlogArticles from '../blog-articles/BlogArticles';
import { HelperService } from '../../services/helperService';

const News = () => {
    const [data, setData] = useState([]);
    
    useEffect(() => {
        const date = new Date();
        date.setFullYear(date.getFullYear()-1);
        fetch(`https://api.io.canada.ca/io-server/gc/news/en/v2?dept=departmentofcitizenshipandimmigration&sort=publishedDate&orderBy=desc&publishedDate%3E=${HelperService.formatInputDate(date)}&pick=50&format=json&atomtitle=Immigration,%20Refugees%20and%20Citizenship%20Canada`).then(res => {
            res.json().then(resp => {
                resp.feed.entry.map(e => {
                    e.publishedDate = HelperService.formatDisplayDate(e.publishedDate);
                })
                setData(resp.feed.entry);
            })
        });
    }, []);
    return (<>
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                    <h5 class="fw-bold text-primary text-uppercase">News</h5>
                    <h1 class="mb-0">Stay Updated with the Latest News</h1>
                </div>
                <div class="row g-5">
                    {
                        data.map((d, i) => {
                            return <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s"><BlogArticles key={'news' + i} date={d.publishedDate} title={d.title} description={d.teaser} link={d.link} openInNewTab={true}/></div>
                        })
                    }
                </div>
            </div></div>
    </>);
}

export default News;