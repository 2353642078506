import React, { useState } from 'react';
import { email, location, phone, phoneDisplay } from '../../config/config';
import './ContactUs.css';
import { EmailService } from '../../services/emailService';

const ContactUs = () => {
    let [message, setMessage] = useState('');
    const sendEmail= (e) => {
        e.preventDefault();
        setMessage('');
        const form = document.forms['contactus'];
        EmailService.sendEmail(form.name.value, form.phone.value, form.email.value, form.reason.value, "", form.message.value).then(res=>{
            setMessage('Thank you for contacting us. We will get back to you shortly!');
            form.reset();
        }).catch(res=>{
            setMessage('An error occurred, please try again later!');
        })
    }

    return (<>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container py-5">
                <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: 600}}>
                    <h5 class="fw-bold text-primary text-uppercase">Contact Us</h5>
                    <h1 class="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
                </div>
                <div class="row g-5 mb-5">
                    <div class="col-lg-4">
                        <div class="d-flex wow fadeIn" data-wow-delay="0.1s">
                            <div class="bg-primary d-flex align-items-center justify-content-center rounded" style={{height: 60, width: 60}}>
                                <i class="fa fa-phone-alt text-white"></i>
                            </div>
                            <div class="ps-4 contact-value">
                                <h5 class="mb-2">Call to ask any question</h5>
                                <a href={'tel:'+phone}><h4 class="text-primary mb-0">{phoneDisplay}</h4></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="d-flex wow fadeIn" data-wow-delay="0.4s">
                            <div class="bg-primary d-flex align-items-center justify-content-center rounded" style={{height: 60, width: 60}}>
                                <i class="fa fa-envelope-open text-white"></i>
                            </div>
                            <div class="ps-4 contact-value">
                                <h5 class="mb-2">Email to get free quote</h5>
                                <a href={'mailto:' + email}><h4 class="text-primary mb-0">{email}</h4></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="d-flex wow fadeIn" data-wow-delay="0.8s">
                            <div class="bg-primary d-flex align-items-center justify-content-center rounded" style={{height: 60, width: 60}}>
                                <i class="fa fa-map-marker-alt text-white"></i>
                            </div>
                            <div class="ps-4 contact-value">
                                <h5 class="mb-2">Visit our office</h5>
                                <a target='_blank' href={"https://www.google.com/maps/search/" + location}><h4 class="text-primary mb-0">{location}</h4></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                        <form onSubmit={sendEmail} name="contactus">
                            <div class="row g-3">
                                <div class="col-md-6">
                                    <input name="name" type="text" required class="form-control border-0 bg-light px-4" placeholder="Your Name" style={{height: 55}}/>
                                </div>
                                <div class="col-md-6">
                                    <input name="email" type="email" required class="form-control border-0 bg-light px-4" placeholder="Your Email" style={{height: 55}}/>
                                </div>
                                <div class="col-md-6">
                                    <input name="phone" type="number" required class="form-control border-0 bg-light px-4" placeholder="Your Phone" style={{height: 55}} />
                                </div>
                                <div class="col-md-6">
                                    <select name="reason" class="form-select bg-light border-0" style={{height: 55}}>
                                        <option selected value="General Inquiry">Select A Reason</option>
                                        <option value="Visiting">Visiting</option>
                                        <option value="Student">Student</option>
                                        <option value="Work">Work</option>
                                        <option value="PNP">PNP</option>
                                        <option value="Spousal">Spousal</option>
                                        <option value="General Inquiry">General Inquiry</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <textarea name="message" required class="form-control border-0 bg-light px-4 py-3" rows="4" placeholder="Message"></textarea>
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-primary w-100 py-3" type="submit">Submit Query</button>
                                </div>
                                {message ? <div className='col-12'>
                                    <p className='text-dark'>{message}</p>
                                </div>: null}
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
                        <iframe class="position-relative rounded w-100 h-100"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2610.396691091432!2d-122.8432431!3d49.13609220000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485da2dd834bded%3A0xd3068cbc1045df97!2s7337%20137%20St%2C%20Surrey%2C%20BC%20V3W%201A4!5e0!3m2!1sen!2sca!4v1727634544990!5m2!1sen!2sca"
                            frameborder="0" style={{minHeight: 350, border: 0}} allowfullscreen="" aria-hidden="false"
                            tabindex="0"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default ContactUs;