import React from 'react';
import { Route, Routes } from 'react-router';
import FamilySponsorship from './family-sponsorship/FamilySponsorship';
import ContactSection from '../contact-section/ContactSection';
import SpouseSponsorship from './spouse-sponsorship/SpouseSponsorship';
import ExpressEntry from './express-entry/ExpressEntry';
import HnC from './hnc/HnC';
import PNP from './PNP/PNP';
import Business from '../visa/business/Business';
import CareGiver from '../visa/care-giver/CareGiver';
import Student from '../visa/student/Student';
import Super from '../visa/super/Super';
import WorkPermit from '../visa/work-permit/WorkPermit';
import Visitor from '../visa/visitor/Visitor';
import { NavLink } from 'react-router-dom';

const Immigration = () => {
    return ( <>
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="row g-5">
            <div className="col-lg-4">
                    <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                        <div className="section-title section-title-sm position-relative pb-3 mb-4">
                            <h3 className="mb-0">Popular Links</h3>
                        </div>
                        <div className="link-animated d-flex flex-column justify-content-start">
                            <NavLink className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" to="/immigration/expressentry"><i className="bi bi-arrow-right me-2"></i>Express Entry</NavLink>
                            <NavLink className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" to="/visa/visitor"><i className="bi bi-arrow-right me-2"></i>Visitor Visa</NavLink>
                            <NavLink className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" to="/visa/student"><i className="bi bi-arrow-right me-2"></i>Students Visa</NavLink>
                            <NavLink className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" to="/visa/work"><i className="bi bi-arrow-right me-2"></i>Work Permit</NavLink>
                            <NavLink className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" to="/visa/business"><i className="bi bi-arrow-right me-2"></i>Business Visa</NavLink>
                            <NavLink className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" to="/immigration/pnp"><i className="bi bi-arrow-right me-2"></i>Provincial Nomination</NavLink>
                        </div>
                    </div>
                    

                    <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                        <img src="/assets/img/blog-1.jpg" alt="" className="img-fluid rounded"/>
                    </div>
                    {/* <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                        <div className="section-title section-title-sm position-relative pb-3 mb-4">
                            <h3 className="mb-0">Tag Cloud</h3>
                        </div>
                        <div className="d-flex flex-wrap m-n1">
                            <a href="" className="btn btn-light m-1">Design</a>
                            <a href="" className="btn btn-light m-1">Development</a>
                            <a href="" className="btn btn-light m-1">Marketing</a>
                            <a href="" className="btn btn-light m-1">SEO</a>
                            <a href="" className="btn btn-light m-1">Writing</a>
                            <a href="" className="btn btn-light m-1">Consulting</a>
                            <a href="" className="btn btn-light m-1">Design</a>
                            <a href="" className="btn btn-light m-1">Development</a>
                            <a href="" className="btn btn-light m-1">Marketing</a>
                            <a href="" className="btn btn-light m-1">SEO</a>
                            <a href="" className="btn btn-light m-1">Writing</a>
                            <a href="" className="btn btn-light m-1">Consulting</a>
                        </div>
                    </div>
                    <div className="wow slideInUp" data-wow-delay="0.1s">
                        <div className="section-title section-title-sm position-relative pb-3 mb-4">
                            <h3 className="mb-0">Plain Text</h3>
                        </div>
                        <div className="bg-light text-center" style={{padding: 30}}>
                            <p>Vero sea et accusam justo dolor accusam lorem consetetur, dolores sit amet sit dolor clita kasd justo, diam accusam no sea ut tempor magna takimata, amet sit et diam dolor ipsum amet diam</p>
                            <a href="" className="btn btn-primary py-2 px-4">Read More</a>
                        </div>
                    </div> */}
                </div>
                <div className="col-lg-8">
                    <Routes>
                        <Route path='/family' element={<FamilySponsorship/>}></Route>
                        <Route path='/spouse' element={<SpouseSponsorship/>}></Route>
                        <Route path='/expressentry' element={<ExpressEntry/>}></Route>
                        <Route path='/pnp' element={<PNP/>}></Route>
                        <Route path='/hnc' element={<HnC/>}></Route>
                        <Route path='/business' element={<Business/>}></Route>
                        <Route path='/caregiver' element={<CareGiver/>}></Route>
                        <Route path='/student' element={<Student/>}></Route>
                        <Route path='/super' element={<Super/>}></Route>
                        <Route path='/visitor' element={<Visitor/>}></Route>
                        <Route path='/work' element={<WorkPermit/>}></Route>
                    </Routes>
                </div>
            </div>
        </div>
    </div>
    <hr/>
    <ContactSection/>
    </> );
}
 
export default Immigration;