import React from 'react';

const BlogArticles = ({category, name, date, title, description, imageUrl, link, openInNewTab}) => {
    let targetProp = {};
    if(openInNewTab) {
        targetProp= {
            "target": "_blank"
        };
    }
    return (<>
            <div class="blog-item bg-light rounded overflow-hidden">
                {imageUrl ? <div class="blog-img position-relative overflow-hidden">
                    <img class="img-fluid" src={imageUrl} alt=""/>
                        {category ?<span class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4">{category}</span> : null}
                </div> : null}
                <div class="p-4">
                    <div class="d-flex mb-3">
                        {name ? <small class="me-3"><i class="far fa-user text-primary me-2"></i>{name}</small> : null}
                        {date ? <small><i class="far fa-calendar-alt text-primary me-2"></i>{date}</small> : null}
                    </div>
                    {title ? <h4 class="mb-3">{title}</h4> : null}
                    {description ? <p>{description}</p> : null}
                    {link ? <a class="text-uppercase" href={link} {...targetProp}>Read More <i class="bi bi-arrow-right"></i></a> : null}
                </div>
            </div>
    </>);
}

export default BlogArticles;