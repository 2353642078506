import React from 'react';
import Accordion from '../../accordion/Accordion';

const Super = () => {
    const accordionData = {
        title: 'Frequently asked Questions',
        data: [
            { title: 'How Much Time Can I Use a Super Visa?', description: 'Anyone entering Canada on a Super Visa is permitted to stay for up to seven years at a time.' },
            { title: 'For whom is the Canada Super Visa valid?', description: 'Parents or grandparents of Canadian citizens or permanent residents are permitted to stay in Canada for longer periods of time than the typical six months each visit with a Super Visa Canada. The Canada parent and grandparent visa is another name for the document.' },
            { title: 'What are the essential details for an applicant for Express Entry to be aware of?', description: 'You will need to provide personal information, prepare your educational records, and pay Immigration Canada when you submit your application for processing. It is free to submit the profile.' }
        ]
    };
    
    return (<>
        <div className="mb-5">
            <img class="img-fluid w-100 rounded mb-5" src="/assets/img/super.png" alt="" />
            <h3 className='mb-3'>What is the Super Visa?</h3>
            <p>This visa is a short-term, multiple-entry permit that allows parents and grandparents to enter the country for up to two years at a time.</p>
            <h3 className='mb-3'>What makes Super Visa special?</h3>
            <p>It permits the applicant to remain for a long time. No more than two years</p>
            <p>It is intended for Canadian citizen parents or grandparents.</p>
            <p>The Parents and Grandparents Super Visa also has the benefit of having a comparatively fast processing time—roughly eight weeks.</p>
            <h3 className='mb-3'>Super Visa Applicability</h3>
            <ul className='immigration-list'>
                <li><i className='bi bi-arrow-right-circle'></i> Qualifiable for a Canada Visitor Visa</li>
                <li><i className='bi bi-arrow-right-circle'></i> A current passport</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of relationship</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of Health Insurance</li>
            </ul>
            <p>Canadian family member of the applicant Qualifications Prove that they are a citizen or permanent resident of Canada; Send a letter of invitation to the candidate. Show that their revenue is more than the set minimal amount: LICO: Go here to find out amounts The system creates a score when the profile is generated based on the data and documents that are entered. Enhancing one’s language proficiency or having a prearranged job offer from a Canadian firm can boost an applicant’s score.</p>
            <Accordion data={accordionData.data} title={accordionData.title} uniqueId='workVisaAcc' />
        </div>
    </>);
}

export default Super;