import React from 'react';
import AboutSection from '../about-section/AboutSection';
import FeaturesSection from '../features-section/FeaturesSection';
import ServiceSection from '../service-section/ServiceSection';
import ContactSection from '../contact-section/ContactSection';
import TeamMembers from '../team-members/TeamMembers';
import Counter from '../counter/Counter';
import TestimonialSection from '../testimonial-section/TestimonialSection';
import Success from '../success/Success';
import Feedback from '../feedback/Feedback';

const Home = () => {
    return ( <>
    <Counter/>
        <AboutSection/>
        <FeaturesSection/>
        <ServiceSection/>
        <Success/>
        <TestimonialSection/>
        <TeamMembers/>
        <Feedback />
        <ContactSection/>
    </> );
}
 
export default Home;