import URLs from "../config/urls";
import { Networker } from "./networker"

export class EmailService {
    static sendEmail(name, phone, email, reason, subject, message) {
        return Networker.post(URLs.sendMail, {
            name,
            email,
            phone,
            reason,
            subject,
            message
        });
    }
}