import React from 'react';
import Accordion from '../accordion/Accordion';
import ContactSection from '../contact-section/ContactSection';

const FAQs = () => {
    const sec1 = [
        { title: 'Which application processing time is faster? Express Entry or paper', description: 'The Canadian immigration process is expedited with Express Entry. Certain types of applications cannot be submitted through the online platform.' },
        { title: 'With a Visitor Visa, how long can I stay in Canada?', description: '6 months' },
        { title: 'What is a Super Visa?', description: 'A multi-entry visa known as the “super visa” allows for several entrances over a maximum of ten years. The main distinction is that a 10-year multiple entry visa would only have a six-month status period for each entrance, whereas a super visa permits an individual to stay in Canada for up to five years at a time.' },
        { title: 'What happens if I am unable to go while my visa is still valid?', description: 'Until its validity period expires, your passport remains valid. The entry procedures of the nation or countries you plan to visit could be the only obstacle. Several countries require that your passport be valid for three to six months in order to grant you entry or a travel visa.' },
        { title: 'For whom is a Work Permit Visa required?', description: 'A work permit is necessary for foreign nationals who want to work temporarily in Canada.' },
        { title: 'Can a visitor to Canada visit a hospital?', description: 'You can visit any doctor or hospital in Canada as a foreign guest, provided you cover the cost of care.' },
    ];

    const sec2 = [
        { title: 'In Canada, what is Permanent Residence?', description: 'A permanent resident card, or PR card, is an identity document that attests to an individual’s eligibility to live and work in Canada. Those with a permanent resident (PR) card who are authorized to remain in Canada legally are known as PRs.' },
        { title: 'Is Obtaining PR Easy?', description: 'Obtaining Permanent Residency (PR) in Canada can be a straightforward process for some, but it largely depends on your individual circumstances, such as your skills, work experience, and the immigration program you choose.' },
        { title: 'Does Canada PR require the IELTS?', description: 'It is challenging to state that. In short, no, taking the IELTS is not a strict requirement for obtaining permanent residence in Canada. Think about the next two things: For people seeking economic immigration, such as those applying through, language testing is but one stage on the path to permanent residence in Canada. However, IELTS is not necessary for other family immigration.' },
        { title: 'If I have PR, can I sponsor my wife to come to Canada?', description: 'For those who were sponsored to enter Canada as a spouse or partner, there is a five-year sponsorship ban. Within five years of being a permanent resident, you cannot sponsor a new spouse or partner if you were sponsored by an existing one. This regulation is applicable even if you became a citizen of Canada within those five years.' },
        { title: 'For what length of time may I work in Canada?', description: 'In Canada, there is no time limit on how long you can work as a temporary worker. The job offer from your employer determines how long you can work. if your company required one to recruit you, the duration specified on your Labour Market Impact Assessment.' },
    ];

    const sec3 = [
        { title: 'What evidence of compassion and humanitarianism do I need?', description: 'Give the necessary records. A submission letter outlining their circumstances and supporting their H&C claims should be included. After paying the processing cost, send in their finished application.' },
        { title: 'Which three factors prevent someone from obtaining Canadian citizenship?', description: 'Circumstances that can keep you from obtaining Canadian citizenship Although you are serving your sentence outside of Canada, you are in Canada. You are facing charges, appearing in court, or having an appeal related to an offense. A deportation order is imposed upon you.' },
        { title: 'Can I fly to Canada with a copy of my medical report even if my medical runs out before then?', description: 'If you do not have insurance, you might not be allowed entrance. You can be given a work permit that expires on the same date as your insurance if your policy’s validity is less than the length of time you anticipate remaining in Canada.' }
    ];
    return (<>
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div className='col-12'>
                        <div class="section-title position-relative pb-3 mb-5">
                            <h5 class="fw-bold text-primary text-uppercase">Frequently Asked Questions</h5>
                            <h1 class="mb-0">Here you’ll find answers to the most common questions about immigration.</h1>
                        </div>
                        <p>Our knowledgeable staff is here to assist you in making the most of your immigration. You can get help from our knowledgeable and trustworthy Immigration Consultants to get a good outcome in your case.</p>
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                        <Accordion data={sec1} uniqueId='sec1Acc' />
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                        <Accordion data={sec2} uniqueId='sec2Acc' />
                    </div>
                    <div className='col-12'>
                        <Accordion title='Special Issues' data={sec3} uniqueId='sec3Acc' />
                    </div>
                </div>
            </div>
        </div>
        <ContactSection/>
    </>);
}

export default FAQs;