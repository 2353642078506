import React from 'react';
import { appName, phone, phoneDisplay } from '../../config/config';
import { NavLink } from 'react-router-dom';

const AboutSection = () => {
    return ( <>
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title section-title-plane position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
                        <h1 className="mb-0">The Best Immigration Consultants With More Than 5 Years of Experience</h1>
                    </div>
                    <p className="mb-4 text-justify">With <b>over 5 years of dedicated service</b>, we at {appName} have been transforming dreams into reality. Our mission is to guide you through every step of your immigration journey with <b>expertise, integrity, and personalized care.</b></p>
                    <div className="row g-0 mb-3">
                        <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Quality Services</h5>
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Expert Team</h5>
                        </div>
                        <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Tailored Solutions</h5>
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Transparent Process</h5>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
                        <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: 60, height: 60}}>
                            <i className="fa fa-phone-alt text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="mb-2">Call to ask any question</h5>
                            <a href={'tel:'+phone}><h4 className="text-primary mb-0">{phoneDisplay}</h4></a>
                        </div>
                    </div>
                    <NavLink to="/contact" className="btn btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">Submit a Query</NavLink>
                </div>
                <div className="col-lg-5" style={{minHeight: 500}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="/assets/img/about.jpg" style={{objectFit: 'cover'}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </> );
}
 
export default AboutSection;