import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/header/Header';
import Carousel from './components/carousel/Carousel';
import Footer from './components/footer/Footer';
import { HashRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/home/Home';
import AboutSection from './components/about-section/AboutSection';
import { Suspense, useEffect } from 'react';
import Immigration from './components/immigration/Immigration';
import Visa from './components/visa/Visa';
import News from './components/news/News';
import FAQs from './components/faqs/FAQs';
import ContactUs from './components/contact-us/ContactUs';
import Login from './components/login/Login';
import ServiceSection from './components/service-section/ServiceSection';
import TestimonialSection from './components/testimonial-section/TestimonialSection';
import Feedback from './components/feedback/Feedback';
import ResetPassword from './components/reset-password/reset-password';

function App() {
  const AdminComponent = React.lazy(() => import('./components/admin/admin'));
  const CalculatorComponent = React.lazy(() => import('./components/calculators/calculators'));

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0,0);
    console.log(location);
  }, [location]);
  return (
    <>
      <Header></Header>
      <Routes>
        <Route path="/">
          <Route index element={<Home/>}/>
          <Route path='login' element={<Login/>}/>
          <Route path='reset-password/:id?' element={<ResetPassword/>}/>

          <Route path='admin/*' element={<Suspense fallback={<div>Loading...</div>}>
            <AdminComponent />
          </Suspense>}/>

          <Route path='calculators/*' element={<Suspense fallback={<div>Loading...</div>}>
            <CalculatorComponent />
          </Suspense>}/>

          <Route path='contact' element={<ContactUs/>}/>
          <Route path='about' element={<AboutSection/>}/>
          <Route path='news' element={<News/>}/>
          <Route path='faqs' element={<FAQs/>}/>
          <Route path='services' element={<ServiceSection/>}/>
          <Route path='testimonial' element={<TestimonialSection/>}/>
          <Route path='feedback' element={<Feedback/>}/>
          <Route path='immigration/*' element={<Immigration/>}/>
          <Route path='visa/*' element={<Visa/>}/>
        </Route>
      </Routes>
      <Footer></Footer>
    </>
  );
}

export default App;
