import React, { useEffect, useState } from 'react';
import { AdminService } from '../../services/adminService';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const Login = () => {

    const checkLoggedIn = () => {
        AdminService.loggedInUser().then(res => {
            if (res && res.data && res.data.data) {
                navigate('/admin/dashboard');
            }
        })
    }

    useEffect(() => {
        checkLoggedIn();
    }, []);

    const navigate = useNavigate();

    let [message, setMessage] = useState('');

    const loginUser = (e) => {
        e.preventDefault();
        setMessage('');
        const form = document.forms['loginform'];
        // navigate('/admin/dashboard');
        // return;
        AdminService.LoginUser(form.username.value, form.password.value).then(res => {
            console.log(res);
            if (res && res.data && res.data.success) {
                navigate('/admin/dashboard');
            }
            else {
                setMessage(res.data.message);
            }
        }).catch(() => {
            setMessage('Unable to validate!');
        });
    }

    return (<>
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container pb-5">
                <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                    <h5 class="fw-bold text-primary text-uppercase">Login</h5>
                </div>
                <div class="row g-5 mb-5">
                    <div className='col-sm-12 col-md-4 offset-md-4 p-4 border'>
                        <form onSubmit={loginUser} name="loginform">
                            <div class="form-floating mb-3">
                                <input type="text" required class="form-control" id="floatingInput" placeholder="" name="username" />
                                <label for="floatingInput">Username</label>
                            </div>
                            <div class="form-floating">
                                <input type="password" required class="form-control" id="floatingPassword" placeholder="" name="password" />
                                <label for="floatingPassword">Password</label>
                            </div>
                            <div className='d-flex justify-content-between mt-3'>
                                <Link to="/reset-password">Reset Password</Link>

                                <button type="submit" class="btn btn-primary">Login</button>
                            </div>
                            {message ? <div className='alert-danger p-2 mt-3'>
                                <p className='text-danger m-0'>{message}</p>
                            </div> : null}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Login;