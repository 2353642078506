import React from 'react';
import './PNP.css';
import Accordion from '../../accordion/Accordion';

const PNP = () => {
    const accordionData = {
        title: 'Frequently asked Questions',
        data: [
            {title:'What is the cost of using a PNP to immigrate?', description:'The cost of an application and other costs for the provincial program ranges from $2,300 to $3,800 CAD per applicant.'},
            {title:'Can I use a PNP to immigrate if I don\'t have a job offer?', description:'For foreign applicants who have not received a job offer in Canada, there are numerous Provincial Nominee Programs (PNPs). Applicants with job experience or skills that can assist the province in addressing labor market or demographic deficiencies are usually the focus of these programs.'},
            {title:'How long does it take to process an application for PNP?', description:'Nomination applications are normally processed within a few months, though this varies by province.'}
        ]
    };
    return (<>
        <div className="mb-5">
            <img class="img-fluid w-100 rounded mb-5" src="/assets/img/PNP.avif" alt="" />
            <p>Programs for provincial nomination intended for people who wish to reside in a particular province and who possess the training, education, and work experience necessary to support the economy of that province.</p>
            <p>For those who wish to immigrate to a particular Canadian province or territory, Canada’s Provincial Nominee Programs (PNPs) provide a route to permanent residence in Canada.</p>
            <p>Every Canadian province and territory has its own PNP, which is tailored to the unique economic and demographic requirements of the region.</p>
            <h3 className='mb-3'>Provincial Program Overview</h3>
            <p>For a business trip, you need to apply for a Business Schengen Visa at the consulate or embassy of the country you will be visiting most frequently.</p>
            <div className="row g-5 mb-3">
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/pnp/1.png' />
                        <h6 className="mb-3-invalid">Discover the Perfect PNP for You</h6>
                        <p className="m-0">The first step in a PNP program is to ensure you meet the specific eligibility requirements and regulations set by the respective province.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/pnp/2.png' />
                        <h6 className="mb-3-invalid">Approval from the PNP office</h6>
                        <p className="m-0">Once the Provincial Program has given its approval, you can submit your Express Entry application.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="pnp service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <img className='service-img mb-3' src='/assets/img/pnp/3.png' />
                        <h6 className="mb-3-invalid">Express Etnry</h6>
                        <p className="m-0">You can apply or update your Express Entry Profile to receive 600 points toward your score after receiving approval from the PNP program.</p>
                    </div>
                </div>
            </div>
            <h3 className='mb-3'>What's required for PNP?</h3>
            <ul className='immigration-list'>
                <li><i className='bi bi-arrow-right-circle'></i> You must submit the most current snapshot you took.</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of close ties to the host province</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of paid visa application fees</li>
                <li><i className='bi bi-arrow-right-circle'></i> Evidence of work experience in a field that is in demand</li>
            </ul>
            <Accordion data={accordionData.data} title={accordionData.title} uniqueId='pnpAccc'/>
        </div>
    </>);
}

export default PNP;